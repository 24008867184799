.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto; 
    position: fixed; 
    top: 5%;
    left: 0;
    right: 0;
    border: 2px solid hsl(217, 16%, 45%) ;
    border-radius: 20px;
    max-width: 40%;
}
.logo{
    margin: 20px;
}
.score{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 3vw;
    background: #fff;
    border-radius: 10px;
    margin: 20px;
}
.score h2{
    color:hsl(229, 64%, 46%);
    letter-spacing: .2rem;
    text-transform: uppercase;
    font-size: 18px;
    margin: 20px 0px 0px 0px;
    font-weight: 600;
}
.score h1{
    color:hsl(229, 25%, 31%);
    font-size: 50px;
    font-weight: 700;
    margin: 0px 0px 10px 0px  ;
}

@media (max-width: 1180px), (max-height: 950px) {
    .header{
        max-width: 80%;
       border-radius: 10px;
    }
    .logo{
        
        margin: 10px;
        height: 60px;
        width: 60px;
    }
    .score{
        padding: 0px 1.5vw;
        border-radius: 5px;
        margin: 10px;
    }
    .score h2{
        letter-spacing: .1rem;
        font-size: 10px;
        margin: 10px 0px 0px 0px;
    }
    .score h1{
        font-size: 30px;
        font-weight: 700;
        margin: 0px 0px 10px 0px  ;
    }
    
  }