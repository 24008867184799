.rules{
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 10px ;   
    padding: 40px;


}
.rules-img {
    height: 40vh;

}
.rules .top button{
    background: transparent ;
    border: none;
    padding: 0px;
    cursor: pointer;
} 
.rules .top h1{
    padding: 0px;
} 
.rules .top{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

}


  


@media (max-width: 1180px), (max-height: 950px) {
    .rules{
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 1;
        border-radius: 0;
        margin: 0;
    }
    .rules-img {
        max-width:  40%;
        max-width:  80%;
        z-index: 2;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 30%;
    
    }
    .rules .top button{
        background: transparent ;
        border: none;
        padding: 0px;
        cursor: pointer;
        position: fixed;
        bottom:5%;
        left: 50%;
        transform: translateX(-50%);
    } 
    .rules .top h1{
        padding: 0px;
        position: fixed;
        top:5%;
        left: 50%;
        transform: translateX(-50%);
    } 
    
  }
