.choice {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 600px;
  height: 500px;
  position: fixed;
  background-color: transparent;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.choice::before {
  content: "";
  background-image: url(../../assets/bg-triangle.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-25%, -35%);
}

.choice .btn {
  width: 220px;
  height: 220px;
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}
.choice .btn:hover {
  --hover-size: scale(1.2);
  transform: var(--hover-size);
  transition: 0.5s ease-out;
}

.paper {
  border: 25px solid hsl(230, 89%, 62%);
  box-shadow: 0px 10px 1px 0px hsla(230, 89%, 62%, 0.575),
    /* Outside shadow on the bottom */ inset 0px 6px 1px 0px
      hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */

  top: 0;
  left: 0;
}
.paper:hover {
  background-color: hsl(230, 89%, 65%);
}

.scissors {
  border: 25px solid hsl(39, 89%, 49%);
  box-shadow: 0px 10px 1px 0px hsla(39, 89%, 49%, 0.575),
    /* Outside shadow on the bottom */ inset 0px 6px 1px 0px
      hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */

  top: 0;
  right: 0;
}
.scissors:hover {
  background-color: hsl(40, 84%, 53%);
}

.rock {
  border: 25px solid hsl(349, 71%, 52%);
  box-shadow: 0px 10px 1px 0px hsla(349, 71%, 52%, 0.575),
    /* Outside shadow on the bottom */ inset 0px 6px 1px 0px
      hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */

  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}
.rock:hover {
  background-color: hsl(349, 70%, 56%);
  left: 33%;
}

.paper img,
.rock img,
.scissors img {
  height: 50%;
}

@media (max-width: 1180px), (max-height: 950px) {
  .choice {
    width: 300px;
    height: 400px;
  }
  .choice::before {
    background-size: 90%;
    transform: translate(-45%, -35%);
  }
  .choice .btn {
    width: 110px;
    height: 110px;
  }
  .choice .btn:hover {
    --hover-size: scale(1.3);
    transform: var(--hover-size);
  }

  .paper {
    border: 15px solid hsl(230, 89%, 62%);
    box-shadow: 0px 6px 1px 0px hsla(230, 89%, 62%, 0.575),
      /* Outside shadow on the bottom */ inset 0px 3px 1px 0px
        hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */
    transform: translate(0%, 20%);
  }
  .scissors {
    border: 15px solid hsl(39, 89%, 49%);
    box-shadow: 0px 6px 1px 0px hsla(39, 89%, 49%, 0.575),
      /* Outside shadow on the bottom */ inset 0px 3px 1px 0px
        hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */
    transform: translate(0%, 20%);
  }
  .rock {
    border: 15px solid hsl(349, 71%, 52%);
    box-shadow: 0px 6px 1px 0px hsla(349, 71%, 52%, 0.575),
      /* Outside shadow on the bottom */ inset 0px 3px 1px 0px
        hsla(0, 0%, 0%, 0.267); /* Inside shadow on the top */
    bottom: 10%;
    transform: translate(-50%, 0%);
  }
}
